import './App.css';
import React, { useRef, useEffect, useState } from 'react';


function App() {
  var midnight = new Date();

  // Set the hours, minutes, and seconds to 0
  midnight.setHours(0);
  midnight.setMinutes(0);
  midnight.setSeconds(0);

  // Add 1 day to the Date object
  midnight.setDate(midnight.getDate() + 1);

  const [done, setDone] = useState("/".repeat(Math.round((midnight-new Date().getTime())/1000)));

  useEffect(() => {
    const interval = setInterval(async () => {
      var midnight = new Date();
  
      // Set the hours, minutes, and seconds to 0
      midnight.setHours(0);
      midnight.setMinutes(0);
      midnight.setSeconds(0);
  
      // Add 1 day to the Date object
      midnight.setDate(midnight.getDate() + 1);

      setDone("/".repeat(Math.round((midnight-new Date().getTime())/1000)));
    }, 200);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (

    <div className='something bg-[black]'>
      <span className='text-[yellow] bg-[red]'>/</span>
      <span>{done}</span>
      <span className='text-[yellow] bg-[red]'>/</span>
      <br></br><br></br><br></br><br></br>
    </div>
  );
}

export default App;
